<template>
<div>
<div class="col-md-12">
    <div class="row" style="margin-top:20px">
        <div class="col-md-6">
            <h3 class="page-header">
            <i class="fa fa fa-users animated bounceInDown show-info"></i>
            Scheduled Jobs
            </h3>
        </div>
        <div class="col-md-6 ">
            <div class="row" style="padding-top:18px;justify-content:flex-end;">
                <div class="col-md-2" style="margin-right:25px">   
                    <button @click="back()" class="btn btn-primary" ><i class="fa fa-backward"></i>  Back</button>
                </div> 
                <div class="col-md-2" style="margin-right:25px;">             
                    <button @click="refresh()" class="btn btn-primary" ><i class="fa fa-refresh"></i>  Refresh</button>
                </div>
            </div>
        </div>
    </div>
</div>
    
	<!-- <div class="row" v-if="viewno==1">
        <div class="col-md-6">
            <div class="info-box  bg-success-dark  text-white">
                <div class="info-icon" style="background-color:#7e7e7e">
                    <i class="fa fa-calendar fa-2x"></i>
                </div>
                <div class="info-details">
                    <h4>Total Scheduled Customer: <span class="badge bg-danger" style="font-size:medium;">{{crmschedulecount}}</span></h4>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="info-box  bg-danger-dark  text-white">
                <div class="info-icon" style="background-color:#7e7e7e">
                    <i class="fa fa-calendar fa-2x"></i>
                </div>
                <div class="info-details">
                    <h4>Total Disposed Customer:  <span class="badge bg-success" style="font-size:medium;">{{disposedcount}}</span> </h4>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <crm-search :displayfor="2"></crm-search> -->
    <div class="row">
            <div class="col-md-12">
                <div class="panel-body ">                   
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-horizontal cascde-forms">

                                    <div class="form-group flex-row CHK">
                                        <label class="col-lg-4 col-md-3 control-label">Applicant Name</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="name" type="text" value="" />
                                        </div>
                                    </div>
                                    <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Qualification</label>
                                        <div class="col-lg-8 col-md-9">
                                            <select class="form-control form-cascade-control input-small" v-model="qualification"><option value="0">SELECT</option>
                                                <option v-for="option in getmastertype(QUALIFICATION_TYPE)"
                                                v-bind:key="option.labelid" v-bind:value="option.labelid"
                                                v-text="option.label" />
                                            </select>
                                        </div>
                                    </div>  
                                    <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Status</label>
                                        <div class="col-lg-8 col-md-9">
                                            <select class="form-control form-cascade-control input-small" v-model="status" name="status">
                                                <option :value="0">Applied</option>
                                                <option :value="1">SHORT LISTED</option>
                                                <option :value="2">APPOINTED</option>
                                                <option :value="3">REJECTED</option>
                                                <option :value="4">TERMINATED</option>
                                            </select>
                                        </div>
                                    </div> 
                                    <div class="form-group flex-row CHK">
                                        <label class="col-lg-4 col-md-3 control-label">Pincode</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="pincode" type="number"/>
                                        </div>
                                    </div>  
                                    <div class="form-group flex-row CHK">
                                        <label class="col-lg-4 col-md-3 control-label">Applicant No.</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="applicantid" type="number"/>
                                        </div>
                                    </div>
                                    <div class="form-group flex-row CHK">
                                        <label class="col-lg-4 col-md-3 control-label">Scheduled Date From</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input type="date" class="form-control form-cascade-control input-small" v-model="scheduledatefrom" @input="refresh()">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 CHK">
                                <div class="form-horizontal cascde-forms">
                                    <div class="form-group flex-row CHK">
                                        <label class="col-lg-4 col-md-3 control-label">Applicant Mobile</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="mobileno" type="number"/>
                                        </div>
                                    </div>
                                    <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Apply For</label>
                                        <div class="col-lg-8 col-md-9">
                                            <select class="form-control form-cascade-control input-small" v-model="position"><option value="0">SELECT</option>
                                                <option v-for="option in getmastertype(APPLY_FOR_POSITION)"
                                                v-bind:key="option.labelid" v-bind:value="option.labelid"
                                                v-text="option.label" />
                                            </select>          
                                        </div>
                                    </div>
                                    <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Remark</label>
                                        <div class="col-lg-8 col-md-9">
                                            <select class="form-control form-cascade-control input-small" v-model="remark"><option value="0">SELECT</option>
                                                <option :value="1">Excellent</option>
                                                <option :value="2">Average</option>
                                                <option :value="3">Poor</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group flex-row CHK">
                                        <label class="col-lg-4 col-md-3 control-label">City</label>
                                        <div class="col-lg-8 col-md-9">
                                            <select class="form-control form-cascade-control input-small .dropdown-menu" style="background-color: rgb(255, 255, 255);" v-model="city" required>
                                                <option v-for="(item) in getmastertype(MASTER_CITIES)" v-bind:key="item.labelid" :value="item.labelid">{{ item.label }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Is Relevant</label>
                                        <div class="col-lg-8 col-md-9">
                                            <select class="form-control form-cascade-control input-small" v-model="isrelevant">
                                                <option value="">Select</option>
                                                <option :value="0">Relevant</option>
                                                <option :value="1">Not Relevant</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group flex-row CHK">
                                        <label class="col-lg-4 col-md-3 control-label">Scheduled Date To</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input type="date" class="form-control form-cascade-control input-small" v-model="scheduledateto" @input="refresh()">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-md-12 flex-between-row">
                            <div class="col-md-3" style="">
                                <button type="submit" @click="clear()"  class="btn bg-primary text-white btn-md">Clear <i class='bx bx-x'></i></button>
                            </div>
                            <div class="col-md-9" style="">
                                <button type="submit" @click="refresh()"  class="btn bg-primary text-white btn-md">Search <i class='bx bx-search-alt-2'></i></button>
                            </div>
                            
                        </div>
                    </div>
            </div>
        </div>
    <div class="row" v-if="viewno==1">
        <div class="col-md-12 mt-20 card-type">
            <div class="row">
                <div class="col-md-3 row">
                    <div class="col-md-5">
                        <strong>Show Rec.</strong>
                    </div>
                    <div class="col-md-6">
                        <select class="form-control col-mb-1" v-model="noofrec" id="rec" @change="refresh()">
                            <option value=10>10</option>
                            <option value=25>25</option>
                            <option value=50>50</option>
                            <option value=100>100</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 pt-20 panel">
            <div class="flex-between-row panel-body">
                <div class="">
                    <span class="control-label">Total Applied:</span>
                    <span class="badge bg-seagreen">
                       {{ appliedcount }}</span>
                </div>
                <div class="">
                    <span class="control-label">Total Shortlisted:</span>
                    <span class="badge bg-success">
                       {{ shortlistcount }}</span>
                </div>
                <div class="">
                    <span class="control-label">Total Appointed:</span>
                    <span class="badge bg-success">
                       {{ appointedcount }}</span>
                </div>
                <div class="">
                    <span class="control-label">Total Rejected:</span>
                    <span class="badge bg-danger font-14">{{rejectedcount}}</span>
                </div>
                <div class="">
                    <span class="control-label">Total Male:</span>
                    <span class="badge bg-seagreen font-14">{{malecount}}</span>
                </div>
                <div class="">
                    <span class="control-label">Total Female:</span>
                    <span class="badge bg-seagreen font-14">{{femalecount}}</span>
                </div>
                <!-- <div class="col-md-2 text-end">
                    <input v-if="!generate"
                        type="button"
                        id="btnprint"
                        class="btn bg-success btn-xs text-white"
                        value="Go to Print"
                        @click="generateReport()"
                    />
                    <pdf-test v-if="generate" :scheduleloanemi="scheduleloanemi" :overdues="overduefetch" :comments="visitcomment"></pdf-test>
                </div> -->
            </div>
        
        </div>
        <div class="col-md-12 table-responsive">
            <div class="panel">
                <div class="panel-body" style="overflow-x:auto;padding-left:0px">
                    <table class="table users-table table-condensed table-hover table-bordered table-sm">
                        <thead class="table-font" style="font-size:13px;">
                            <tr>
                                <th>#</th>
                                <th>Applicant No.</th>
                                <th>Name</th>
                                <!-- <th>Zone</th> -->
                                <th>Address</th>
                                <th>Mobiles</th>
                                <th>Alt.Mobiles</th>
                                <th>Gender</th>
                                <th>Experience</th>
                                <th>Qualification</th>
                                <th>Apply For</th>
                                <th>Date of Application</th>
                                <th>Scheduled Date</th>
                                <th>Remark</th>
                                <th>Status</th>
                                <th>Refer By</th>
                                <!-- <th>Edit</th> -->
                                <th>Description</th>
                                <th>Conversation</th>
                                <th>Relevant /<br>Not Relevant</th>
                            </tr>
                        </thead>
                       <crm-scheduled-row v-for="(item,index) in crmschedule" v-bind:key="item.id" :item="item" :index="index" :currentpage="currentpage" :noofrec="noofrec"></crm-scheduled-row>
                    </table>
                     <div class="col-md-12" style="margin-top: 20px">
                        <nav aria-label="Page navigation example" style="text-align: right !important">   
                        <paginate
                            style="margin: 0px !important; float: right"
                            :page-count="noofpages"
                            :click-handler="clickCallback"
                            :prev-text="'&laquo;'"
                            :next-text="'&raquo;'"
                            :container-class="'pagination'">
                        </paginate>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <customer-detail v-if="viewno==3"></customer-detail>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
// import CrmSearch from './CrmSearch.vue';
import CrmScheduledRow from './CrmScheduledRow.vue';
import CustomerDetail from '../customer/CustomerDetail.vue';
import Paginate from "vuejs-paginate";
import moment from 'moment';

export default {
    mixins:[Constants],
	components: {
    	CustomerDetail,Paginate,
        CrmScheduledRow
    },
    data(){
        return{
            activecount:0,
            defaultercount:0,
            currentpage:1,
            noofrec:10,
            moment:moment,
            remark:'',
            crmdata:[],
            crmcount:0,
            scheduledateto:'',
            scheduledatefrom:'',
            disposedcount:0,
            appliedcount:0,
            shortlistcount:0,
            appointedcount:0,
            rejectedcount:0,
            malecount:0,
            femalecount:0,
            city:0,
            isrelevant:0,
            mobileno:'',
            qualification:0,
            name:'',
            position:0,
            pincode:'',
            applicantid:0,
            status:0
        }
    },
	computed:{
			...mapGetters([
		     'viewno','loggedinuser','crmschedule','crmschedulecount'
		    ]),
            noofpages(){
                return Math.ceil(parseInt(this.crmschedulecount) / parseInt(this.noofrec))
            }
        },
    mounted(){
		this.refresh();
	},
	methods:{
        clickCallback (currentpage) {
            this.currentpage=currentpage
            this.$store.commit("assigncurrentpage",currentpage);
            this.refresh();
        },
       
        
        refresh(){
            let param={
                name:this.name,
                mobileno:this.mobileno,
                qualification:this.qualification,
                position:this.position,
                remark:this.remark,
                status:this.status,
                statusquery:3,
                isschedule:0,
                noofrec:this.noofrec,
                currentpage:this.currentpage,
                pincode:this.pincode,
                city:this.city,
                isrelevant:this.isrelevant,
                id:this.applicantid
            }
            this.$store.commit("assignloadingstatus",1)
            this.$http.post("api/recruit/fetch/scheduled",param)
                .then((response) => this.ProcessCRM(response.data))
                .catch((err) => {
                    console.log('', err)
                });
            window.axios.post('api/recruit/fetch/count')
                .then((response) => this.processTotaltCount(response.data))
                .catch((err) => {
                console.log('', err)
            });
        },
        processTotaltCount(data){
            this.appliedcount=data.applied
            this.shortlistcount=data.shortlisted
            this.appointedcount=data.appointed
            this.rejectedcount=data.rejected
            this.malecount=data.male
            this.femalecount=data.female
        },
        ProcessCRM(data){
            this.$store.commit("assignloadingstatus",0)
            // this.crmdata=data
            this.$store.commit("assigncrmschedule",data.recruit)
            this.$store.commit("assigncrmschedulecount",data.count)
        },
        ProcessCRMDisposedcount(data){
            this.disposedcount=data
        },
        back(){
            this.$store.commit('assignviewno',1)
        },     
        clear(){
            this.name=''
            this.mobileno=''
            this.qualificatio=0
            this.position=0
            this.address=''
            this.remark=0
            this.status=0
            this.refresh()
            this.isrelevant=''
        },
    }
}
</script>
<style>
.btn-primary:hover{
    text-decoration: none !important;
    background-color: #3a476b !important;
}
.b-r{
    border-radius: 5px;
}
</style>
