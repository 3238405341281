<template>
 <tbody class="table-font">
        <tr >
            <td>{{ (currentpage - 1) * noofrec + (index + 1) }} </td>
            <!-- <td>
                <span v-if="item.loanemi!=null && item.loanemi.loanaccount!=null">{{item.loanemi.loanaccount.manualid}}</span>
            </td> -->
            <td>{{ item.id}}</td>
            <td>{{ item.name }}</td>
            <td>{{item.address}} </td>
            <td>{{item.mobileno}}</td>
            <td>{{item.altmobileno}}</td>
            <td>{{ getMasterLabelByType(item.gender,MASTER_GENDER)}}</td>
            <td>{{item.experience }} years</td>
            <td>{{ getMasterLabelByType(item.qualification,QUALIFICATION_TYPE)}}</td>
            <td>{{ getMasterLabelByType(item.position,APPLY_FOR_POSITION) }}</td>
            <td>
                {{ item.date!=null?moment(item.date).format("DD-MM-YYYY"):'---' }}
            </td>
            <td>
                <span style="color:orangered;font-weight: bold;">{{ item.date!=null?moment(item.scheduled).format("DD-MM-YYYY"):'---' }}</span>
            </td>
            <td>
                {{ jobremark(item.remark) }}
            </td>
            <td class="text-center" @dblclick="statusmethod(item)" @keyup.enter="statusmethod(item)">
                <select v-if="statusfield" class="form-control form-cascade-control input-small" v-model="item.status" name="status" @change="statusmethod(item)">
                        <option value="0">SELECT</option>
                        <option value="1">SHORT LISTED</option>
                        <option value="2">APPOINTED</option>
                        <option value="3">REJECTED</option>
                        <option value="4">TERMINATED</option>
                </select>
                {{  jobstatus(item.status)}}
            </td>
            <td>
                {{ item.referby }}
            </td>
            <!-- <td v-if="loginusercheck(165)">
                
                <button class="btn btn-success" type="button" @click="edit(item)">
                    <i class='bx bx-edit'></i>
                </button>
            </td> -->
            <td><button class="btn btn-primary" type="button" @click="desc=!desc">Description</button></td>
            <td><button class="badge btn-prime btn-xs" @click="showchats(item)">conversation</button></td>
            <td>
                <button v-if="item.isrelevant==0" class="badge btn-success btn-xs" @click="updateRelevant(item)">Relevant</button>
                <button v-if="item.isrelevant==1" class="badge btn-danger btn-xs" @click="updateRelevant(item)">Not Relevant</button>
            </td>
        </tr>
        <tr v-if="desc" >
            <td colspan="16">Desc :{{item.description  }}</td>
        </tr>
        <tr v-if="visitcomments">
            <td colspan="16">
                <job-conversation :item="item"></job-conversation>
            </td>
        </tr>
    </tbody>
</template>
<script>
import Constants from "../../components/utilities/Constants.vue";
import JobConversation from "./JobConversation.vue";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
    mixins:[Constants],
    props:['item','index','currentpage','noofrec'],
    components: {
        JobConversation
    },
    data() {
        return {
            moment:moment,
            accounttype:0,
            commentlist:[],
            danger:false,
            desc:false,
            statusfield:false,
            visitcomments:false
        }
    },
    computed:{
			...mapGetters([
		     'viewno','loggedinuser',
		    ]),
        },
    methods: {
        showchats(){
            this.visitcomments=!this.visitcomments
        },
        refresh(){
            this.$parent.refresh();
        },
        Disposecrm(){
            if(confirm("Do you really want to dispose")==true){
                this.$store.commit("assignloadingstatus",1)
                let param={id:this.item.id,column:'isdispose',value:1}
                this.$http.post("api/user/updatefield",param)
                .then((response) => this.ProcessdisposeCRM(response.data))
                .catch((err) => {
                    console.log('', err)
                });
            }
        },
        ProcessdisposeCRM(){
            this.$store.commit("assignloadingstatus",0)
            this.$notify({text:'Disposed Successfully',type:'success'})
            this.$parent.refresh();
        },
        statusmethod(item){
            this.statusfield=!this.statusfield
            let param={id:item.id,column:"status",value:item.status}
            this.$http.post('api/recruit/updatefield',param)
            .then((response) => this.processUpdateResponse(response.data))
            .catch((err) => {
                console.log('', err)
            });
        },
        processUpdateResponse(){
            this.$parent.refresh();
        },
        updateRelevant(item){
            if(confirm("Do you really want to change relevant")){
                let param={id:item.id,isrelevant:item.isrelevant==0?1:0}
                this.$http.post('api/recruit/updateIsrelevant',param)
                .then((response) => this.processUpdateResponse(response.data))
                .catch((err) => {
                    console.log('', err)
                });
            }
            
        },
    },
}
</script>